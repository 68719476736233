export const SET_COS_DATA = "SET_COS_DATA";
export const COS_STATUS_FETCHING_INIT = "COS_STATUS_FETCHING_INIT";
export const COS_STATUS_FETCHING_DISMISS = "COS_STATUS_FETCHING_DISMISS"
export const COS_DATA_FETCHING_INIT = "COS_DATA_FETCHING_INIT";
export const COS_DATA_FETCHING_DISMISS = "COS_DATA_FETCHING_DISMISS";
export const FETCH_COS_LIST_COMPLETE = "FETCH_COS_LIST_COMPLETE";
export const FETCH_COS_REPORT = "FETCH_COS_REPORT";
export const SET_REPORTS_FILE_DATA = "SET_REPORTS_FILE_DATA";
export const FETCH_COS_FILE_LIST_COMPLETE = "FETCH_COS_FILE_LIST_COMPLETE";
export const DATA_FETCHING_INIT = "DATA_FETCHING_INIT";
export const DATA_FETCHING_DISMISS = "DATA_FETCHING_DISMISS";
export const OPEN_NEW_COS_UPLOAD_POPUP = "OPEN_NEW_COS_UPLOAD_POPUP";
export const CLOSE_NEW_UPLOAD_POPUP = "CLOSE_NEW_UPLOAD_POPUP";
export const DATA_SUBMIT_INIT = "DATA_SUBMIT_INIT";
export const DATA_SUBMIT_DISMISS = "DATA_SUBMIT_DISMISS";
export const SET_FILTER_STATUS = "SET_FILTER_STATUS";
export const UPLOAD_COS_FILE_COMPLETE = "UPLOAD_COS_FILE_COMPLETE";
export const VIEW_DETAILS_FETCHING_INIT = "VIEW_DETAILS_FETCHING_INIT";
export const VIEW_DETAILS_FETCHING_DISMISS = "VIEW_DETAILS_FETCHING_DISMISS";
export const VIEW_DETAILS_FETCHING_COMPLETE = "VIEW_DETAILS_FETCHING_COMPLETE";
export const ACCEPT_COS_VALIDATION_SUCCESS = "ACCEPT_COS_VALIDATION_SUCCESS";
export const OPEN_COS_REJECT_VALIDATION_POPUP = "OPEN_COS_REJECT_VALIDATION_POPUP";
export const HIDE_COS_VALIDATION_FILE_REJECT = "HIDE_COS_VALIDATION_FILE_REJECT";
export const COS_REJECT_VALIDATION_COMPLETE = "COS_REJECT_VALIDATION_COMPLETE";
export const DELETE_RECORDS_COMPLETE = "DELETE_RECORDS_COMPLETE";
export const DATA_FETCHING_HISTORY_INIT_COS = "DATA_FETCHING_HISTORY_INIT_COS";
export const DATA_HISTORY_FETCHING_DISMISS_COS = "DATA_HISTORY_FETCHING_DISMISS_COS";
export const ASSET_HISTORY_SEARCH_LIST_COMPLETE_COS = "ASSET_HISTORY_SEARCH_LIST_COMPLETE_COS";
export const NEXT_STEP_VIEW_COMPLETE = "NEXT_STEP_VIEW_COMPLETE";
export const COS_FILE_DATA_FETCHING_INIT = "COS_FILE_DATA_FETCHING_INIT";
export const COS_FILE_DATA_FETCHING_DISMISS = "COS_FILE_DATA_FETCHING_DISMISS";
export const COS_VIEW_FETCHING_INIT = "COS_VIEW_FETCHING_INIT";
export const COS_VIEW_FETCHING_DISMISS = "COS_VIEW_FETCHING_DISMISS";
export const COS_VIEW_FETCHING_COMPLETE = "COS_VIEW_FETCHING_COMPLETE";
export const ADD_HISTORY_ASSET_COS_INIT = "ADD_HISTORY_ASSET_COS_INIT"
export const ADD_HISTORY_COS_COMPLTED = "ADD_HISTORY_COS_COMPLTED"
export const ADD_HISTORY_ASSET_COS_DISSMISS = "ADD_HISTORY_ASSET_COS_DISSMISS"
export const COS_EVENT_TYPE_INIT = "COS_EVENT_TYPE_INIT"
export const COS_EVENT_TYPE_COMPLETE = "COS_EVENT_TYPE_COMPLETE"
export const COS_EVENT_TYPE_DISMISS = "COS_EVENT_TYPE_DISMISS"
export const ASSET_DELETE_RECORDS_INIT = "ASSET_DELETE_RECORDS_INIT"
export const ASSET_DELETE_RECORDS_COMPLETE = "ASSET_DELETE_RECORDS_COMPLETE"
export const ASSET_DELETE_RECORDS_DISSMISS = "ASSET_DELETE_RECORDS_DISSMISS"
export const EDIT_ERR_DETAILS_PREVIEW = "EDIT_ERR_DETAILS_PREVIEW"
export const EDIT_ERR_DETAILS_DISMISS = "EDIT_ERR_DETAILS_DISMISS"
export const EDIT_RECORD_PREVIEW = "EDIT_RECORD_PREVIEW"
export const EDIT_RECORD_DISMISS = "EDIT_RECORD_DISMISS"
export const EDIT_RECORD = "EDIT_RECORD"
export const DATA_REFETCH_COMPLETE = "DATA_REFETCH_COMPLETE"
export const DATA_REFETCH_SUBMIT_INIT = "DATA_REFETCH_SUBMIT_INIT";
export const FINAL_VIEW_DETAILS_FETCHING_INIT = "FINAL_VIEW_DETAILS_FETCHING_INIT";
export const FINAL_VIEW_DETAILS_FETCHING_DISMISS = "FINAL_VIEW_DETAILS_FETCHING_DISMISS";
export const FINAL_VIEW_DETAILS_FETCHING_COMPLETE = "FINAL_VIEW_DETAILS_FETCHING_COMPLETE";
export const APPROVAL_REJECTION_DATA_FETCHING_INIT = "APPROVAL_REJECTION_DATA_FETCHING_INIT";
export const APPROVAL_REJECTION_DATA_FETCHING_DISMISS = "APPROVAL_REJECTION_DATA_FETCHING_DISMISS";
export const APPROVAL_REJECTION_DATA_FETCHING_COMPLETE = "APPROVAL_REJECTION_DATA_FETCHING_COMPLETE";
export const COS_ACCEPT_REJECT_INIT = "COS_ACCEPT_REJECT_INIT";
export const COS_ACCEPT_REJECT_COMPLETE = "COS_ACCEPT_REJECT_COMPLETE";
export const COS_ACCEPT_REJECT_DISMISS = "COS_ACCEPT_REJECT_DISMISS";

const cosStates = {
    cos_data_fetching: false,
    search: "",
    page: 1,
    perpage: 10,
    filter_status_cos: "",
    cos_list_refetch: true,
    cos_list: "",
    details_view: 0,
    from: "",
    report_page: 1,
    report_perpage: 10,
    to: "",
    report_search: "",
    option_array: [],
    cos_report_files: [],
    cos_report_refetch: false,
    download_support_flag: false,
    download_support_history_flag: false,
    download_csv: "",
    export_support_flag_value: 1,
    export_support_flag_History_value: 4,
    add_btn_disable: false,
    toggle_btn_flag: false,
    selectedDate: "",
    showSelectedDate: '',
    option: [],
    page_bill: 1,
    perpage_bill: 10,
    cos_billing_report: [],
    cos_billing_report_refetch: true,
    trigger_toggle_button: false,
    syncFlag: '',
    lastUpdateDate: '',
    cos_files: [],
    view_page: 1,
    view_perpage: 10,
    view_details_fetching: false,
    view_details_refetch: false,
    view_details_data: [],
    totalview_details_data: 0,
    view_details_search: "",
    export_View_details_support_flag_value: 1,
    download_view_detail_support_flag: false,
    hist_event_details_search: "",
    hist_event_details_page: 1,
    hist_event_details_perpage: 10,
    hist_event_fetching: false,
    hist_event_refetch: false,
    hist_event_data: [],
    total_hist_event_data: 0,
    export_hist_event_support_flag_value: 1,
    download_hist_event_support_flag: false,
    rem_btn_disable: false,
    remove_popup: false,
    remove_popup_count: 0,//set same popup for remove all and remove single record
    delete_continue_popup: false,
    delete_continue_btn: false,
    view_flag: 0,
    view_count: 0,
    view_cos_approve_popup: false,
    cos_file_details: [],
    cos_approve_btn_disable: false,
    open_add_new: false,
    open_edit_new: false,
    view_cos_reject_popup: false,
    cos_file_id: "",
    cos_confirm_btn_disable: false,
    status: [],
    cos_file_status_refetch: false,
    serialNumber:"",
    assetHistoryData:[],
    count:1000,
    view_cos_details_refetch:true,
    asset_history_fetching:false,
    download_popup:false,
    cos_download_data:{},
    status_fetch:false,
    status_retry:false,
    next_flag:0,
    cos_view_search: "",
    cos_view_page: 1,
    cos_view_perpage: 10,
    total_cos_view_data: 0,
    cos_view_data: [],
    cos_view_fetching: false,
    cos_view_refetch: false,
    steps_fetching: false,
    single_err_id: "",
    current_step: "",
    show_reject_data: false,
    cos_his_add_btn_dsbl: false,
    is_edit: -1,
    cos_event_type: [],
    eventtype_value: "",
    cos_details_history: {},
    cnfm_delete_popup: false,
    dlt_btn_disble: false,
    edit_cos_his_event_date: null,
    status_complete: false,
    historySyncFlag: null,
    historySyncDate: null,
    cos_his_event_type: "",
    cos_his_event_date: null,
    cos_his_supplier_code: "",
    cos_his_mpan: "",
    cos_his_mprn: "",
    edit_cos_his_event_type: null,
    edit_cos_his_supplier_code: null,
    edit_cos_his_mpan: null,
    edit_cos_his_mprn: null,
    s_cos_his_mpan: null,
    s_cos_his_mprn: null,
    direct_to_steps: false,
    cos_his_address: "",
    cos_his_postcode: "",
    edit_cos_his_address: null,
    edit_cos_his_postcode: null,
    //edit error file details 
    editDetail: null,
    e_confirmation_end_date: "",
    e_efd: "",
    e_flowdate: "",
    e_flowname: "",
    e_invoice_no: "",
    e_make_model: "",
    e_map_id: "",
    e_meter_type: "",
    e_mop_id: "",
    e_mp_id_from: "",
    e_mpid_from_role: "",
    e_mpid_to: "",
    e_mpid_to_role: "",
    e_new_supplier_code: "",
    e_old_supplier_code: "",
    e_serial_no: "",
    e_mpan:"",
    e_mprn:"",
    edit_err_btn:false,
    temp_id:null,
    cos_step:null,
    event_complete_flag:false,
    module:1,
    viewdata_not_found:false,
    next_enable:false,
    final_view_page:1,
    final_view_perpage:10,
    final_view_details_fetching:false,
    final_view_details_data:[],
    final_totalview_details_data:0,
    final_view_details_search:"",
    final_view_details_refetch:false,
    final_download_view_detail_support_flag:false,
    final_export_View_details_support_flag_value:1,
    cosFileType:0,
    approval_rejection_page:1,
    approval_rejection_perpage:10,
    approval_rejection_view_fetching:false,
    approval_rejection_view_data:[],
    approval_rejection_total_view_data:0,
    approval_rejection_view_search:"",
    approval_rejection_view_refetch:false,
    single_approve_reject_btn_disabled:false

}
export default (state = cosStates, action = {}) => {

    const actionObject = {
        SET_COS_DATA : ()=>{
            return { ...state, ...action.payload };
        },
        COS_STATUS_FETCHING_INIT : ()=>{
            return { ...state, ...action.payload };
        },
        COS_STATUS_FETCHING_DISMISS : ()=>{
            return { ...state, ...action.payload, cos_file_status_refetch: false, status_fetch: false, status_retry: false };
        },
        DATA_SUBMIT_INIT : ()=>{
            return { ...state, ...action.payload, add_btn_disable: true };
        },
        DATA_SUBMIT_DISMISS : ()=>{
            return { ...state, ...action.payload, add_btn_disable: false };
        },
        UPLOAD_COS_FILE_COMPLETE : ()=>{
            return { ...state, ...action.payload };
        },
        CLOSE_NEW_UPLOAD_POPUP : ()=>{
            return { ...state, ...action.payload };
        },
        OPEN_NEW_COS_UPLOAD_POPUP : ()=>{
            return { ...state, ...action.payload };
        },
        SET_FILTER_STATUS : ()=>{
            return { ...state, ...action.payload };
        },
        SET_REPORTS_FILE_DATA : ()=>{
            return { ...state, ...action.payload };
        },
        FETCH_COS_FILE_LIST_COMPLETE : ()=>{
            return { ...state, ...action.payload };
        },
        DATA_FETCHING_INIT : ()=>{
            return { ...state, ...action.payload, data_fetching: true };
        },
        DATA_FETCHING_DISMISS : ()=>{
            return { ...state, ...action.payload, data_fetching: false };
        },
        VIEW_DETAILS_FETCHING_INIT : ()=>{
            return { ...state, ...action.payload, view_details_fetching: true, };
        },
        VIEW_DETAILS_FETCHING_DISMISS : ()=>{
            return { ...state, ...action.payload, view_details_fetching: false };
        },
        VIEW_DETAILS_FETCHING_COMPLETE : ()=>{
            return { ...state, ...action.payload }
        },
        ACCEPT_COS_VALIDATION_SUCCESS : ()=>{
            return { ...state, ...action.payload };
        },
        OPEN_COS_REJECT_VALIDATION_POPUP : ()=>{
            return { ...state, ...action.payload, view_cos_reject_popup: true };
        },
        HIDE_COS_VALIDATION_FILE_REJECT : ()=>{
            return { ...state, ...action.payload, view_cos_reject_popup: false };
        },
        COS_REJECT_VALIDATION_COMPLETE : ()=>{
            return { ...state, ...action.payload };
        },
        DELETE_RECORDS_COMPLETE : ()=>{
            return { ...state, ...action.payload, view_details_refetch: true, rem_btn_disable: false, remove_popup_count: 0 };
        },
        DATA_FETCHING_HISTORY_INIT_COS : ()=>{
            return { ...state, ...action.payload, asset_history_fetching: true };
        },
        DATA_HISTORY_FETCHING_DISMISS_COS : ()=>{
            return { ...state, ...action.payload, asset_history_fetching: false };
        },
        ASSET_HISTORY_SEARCH_LIST_COMPLETE_COS : ()=>{
            return { ...state, ...action.payload };
        },
        NEXT_STEP_VIEW_COMPLETE : ()=>{
            return { ...state, ...action.payload, cos_file_status_refetch: true, steps_fetching: false };
        },
        COS_FILE_DATA_FETCHING_INIT : ()=>{
            return { ...state, ...action.payload, cos_data_fetching: true };
        },
        COS_FILE_DATA_FETCHING_DISMISS : ()=>{
            return { ...state, ...action.payload, cos_data_fetching: false };
        },
        COS_VIEW_FETCHING_INIT : ()=>{
            return { ...state, ...action.payload, cos_view_fetching: true };
        },
        COS_VIEW_FETCHING_DISMISS : ()=>{
            return { ...state, ...action.payload, cos_view_fetching: false };
        },
        COS_VIEW_FETCHING_COMPLETE : ()=>{
            return { ...state, ...action.payload }
        },
        ADD_HISTORY_ASSET_COS_INIT : ()=>{
            return { ...state, ...action.payload, cos_his_add_btn_dsbl: true, status_complete: false,event_complete_flag:false };
        },
        ADD_HISTORY_COS_COMPLTED : ()=>{
            return { ...state, ...action.payload, };
        },
        ADD_HISTORY_ASSET_COS_DISSMISS : ()=>{
            return { ...state, ...action.payload, cos_his_add_btn_dsbl: false, status_complete: false,event_complete_flag:false };
        },
        COS_EVENT_TYPE_INIT : ()=>{
            return { ...state, ...action.payload };
        },
        COS_EVENT_TYPE_COMPLETE : ()=>{
            return { ...state, ...action.payload };
        },
        COS_EVENT_TYPE_DISMISS : ()=>{
            return { ...state, ...action.payload };
        },
        ASSET_DELETE_RECORDS_INIT : ()=>{
            return { ...state, ...action.payload, dlt_btn_disble: true, status_complete: false,event_complete_flag:false };
        },
        ASSET_DELETE_RECORDS_COMPLETE : ()=>{
            return { ...state, ...action.payload, dlt_btn_disble: false, direct_to_steps: true, view_cos_details_refetch: true, cnfm_delete_popup: false, status_complete: true,event_complete_flag:true };
        },
        ASSET_DELETE_RECORDS_DISSMISS : ()=>{
            return { ...state, ...action.payload, dlt_btn_disble: false, cnfm_delete_popup: false, status_complete: false,event_complete_flag:false };
        },
        EDIT_ERR_DETAILS_PREVIEW : ()=>{
            return { ...state, ...action.payload }
        },
        EDIT_ERR_DETAILS_DISMISS : ()=>{
            return { ...state, editDetail: null }
        },
        EDIT_RECORD_PREVIEW : ()=>{
            return { ...state, ...action.payload, edit_err_btn: true }
        },
        EDIT_RECORD_DISMISS : ()=>{
            return { ...state, edit_err_btn: false }
        },
        EDIT_RECORD : ()=>{
            return { ...state,view_details_refetch: true, edit_err_btn: false }
        },
        DATA_REFETCH_SUBMIT_INIT : ()=>{
            return { ...state, ...action.payload };
        },
        DATA_REFETCH_COMPLETE : ()=>{
            return { ...state, ...action.payload };
        },
        FINAL_VIEW_DETAILS_FETCHING_INIT : ()=>{
            return { ...state, ...action.payload, final_view_details_fetching: true, };
        },
        FINAL_VIEW_DETAILS_FETCHING_DISMISS : ()=>{
            return { ...state, ...action.payload, final_view_details_fetching: false };
        },
        FINAL_VIEW_DETAILS_FETCHING_COMPLETE : ()=>{
            return { ...state, ...action.payload }
        },
        APPROVAL_REJECTION_DATA_FETCHING_INIT : ()=>{
            return { ...state, ...action.payload, approval_rejection_view_fetching: true, };
        },
        APPROVAL_REJECTION_DATA_FETCHING_DISMISS : ()=>{
            return { ...state, ...action.payload, approval_rejection_view_fetching: false };
        },
        APPROVAL_REJECTION_DATA_FETCHING_COMPLETE : ()=>{
            return { ...state, ...action.payload }
        },
        COS_ACCEPT_REJECT_INIT: () => ({ ...state, ...action.payload,single_approve_reject_btn_disabled:true}),
        COS_ACCEPT_REJECT_COMPLETE: () => ({ ...state, ...action.payload, approval_rejection_view_refetch:true,single_approve_reject_btn_disabled:false}),
        COS_ACCEPT_REJECT_DISMISS: () => ({ ...state, ...action.payload,single_approve_reject_btn_disabled:false}),
     
    }

    if (actionObject[action.type] === undefined) {
        return state; // Return unchanged state
    } else {
        // Call the appropriate action handler and return the updated state
        return actionObject[action.type]();
    }
}